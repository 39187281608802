<template>
    <div>
        <div class="container mt-5">
            <div class="row">
                <div class="col-12 text-center pt-3">
                    <h2 class="title pl-1">{{$t('menu.debates')}}</h2>
                </div>
            </div>
    <div class="row" v-if="polls && polls.length == 0">
        <div class="col-12 pb-2">
            <a-empty
                :image="Empty.PRESENTED_IMAGE_SIMPLE"
                :image-style="{
                height: '60px',
                }"
            >
                <template #description>
                <span>
                    {{$t("general.noData")}}
                </span>
                </template>
            </a-empty>
        </div>
    </div>
	<div class="row" v-if="polls">
        <div v-for="index in 5" :key="index" class="col-sm-12 col-md-12 ">
            <debat-card v-if="polls[index - 1]" :data="polls[index - 1]"/>
            <hr class="m-0 mb-1 p-0" v-if="polls[index - 1]"/>
        </div>
    </div>
    <div v-else class="m-5">
        <div class="d-flex justify-content-center">
          <div class="spinner-grow text-primary" role="status">
          </div>
        </div>
    </div>
    <div class="d-flex justify-content-center mx-auto ">
        <router-link to="/polls">
            <button class="btn btn-primary btn-squared mt-5 mb-5">{{$t('general.seeAll')}}</button>
        </router-link>
    </div>
</div>
    </div>
</template>
<script>
import DebatCard from '@/components/DebatCard'

import apiAxios from '@/services/axios'
import { Empty } from 'ant-design-vue';

export default {
    components:{ DebatCard },
    data: () => ({
        Empty,
        data: null,
    }),
    computed: {
    currentLocale () {
      return this.$i18n.locale.slice(0, 2);
    },
		polls () {
			return this.data ? this.data.filter(data => data.name[this.currentLocale]) : null
		},
	},
    created(){
		this.getData();
	},
    
    methods:{
      getData(){
          apiAxios.post('/poll/filter',{
          query:{
              status: "active",
              },
        }).then((res)=>{
          console.log(res.data)
          this.data=res.data;
        }).finally(()=>{
          //this.tableLoading=false
        })
      },
    },
}
</script>
<style lang="scss" scoped>
   @import '@/mixins.scss';
    .title {
        border-bottom: 2px solid $primary;
        width: 7em;
        text-align: left;
    }

    .hr {
        margin:0;
        padding:0;
    }
</style>