<template>
    <div v-if="data">
        <div class="container mt-5">
            <div class="row">
                <div class="col-12 pt-3">
                    <h2 class="title pl-1">{{$t('menu.video')}}</h2>
                </div>
            </div>

	<div class="row gx-5">
          <div v-for="post in data" :key="post._id" class="col-md-4">
            <iframe
              width="100%"
              height="300"
              :src="post.link"
              title="YouTube video player"
              frameborder="1"
              allow=" autoplay; clipboard-write; encrypted-media; picture-in-picture"
              allowfullscreen
            ></iframe>
            <p>{{ post.discription }}</p>
          </div>
    </div>
    <div class="d-flex justify-content-center mx-auto">
       <router-link to="/videos">
            <button class="btn btn-primary btn-squared mt-5 mb-5">{{$t('general.seeAll')}}</button>
        </router-link>
    </div>
</div>
    </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import moment from 'moment'
import localization from 'moment/locale/fr'
import apiAxios from '@/services/axios'

export default defineComponent({
  setup() {
    const value = ref();
    const data=ref()
apiAxios
        .post('/videos/filter', {
          query: { status: 'active' },
        })
        .then((res) => {
          data.value = res.data.slice(0, 3)
        })
    const onPanelChange = (value, mode) => {
      console.log(value, mode);
    };

    return {
        data,
      value,
      onPanelChange,
    };
  },
});
</script>
<style lang="scss" scoped>
   @import '@/mixins.scss';
    .title {
        border-bottom: 2px solid $primary;
        width: 7em;
    }

    .vc-container {
        border-color: $primary;
        border-radius: 0rem;
    }
</style>