<template>
  <div>
    <!--Container-->
    <div class="container mt-5">
      <div class="row">
        <div class="col-12 pt-3">
          <h2 class="title pl-1">{{ $t('menu.news') }}</h2>
        </div>
      </div>
      <div class="row" v-if="news && news.length == 0">
        <div class="col-12 pb-2">
          <a-empty
            :image="Empty.PRESENTED_IMAGE_SIMPLE"
            :image-style="{
              height: '60px',
            }"
          >
            <template #description>
              <span>
                {{ $t('general.noData') }}
              </span>
            </template>
          </a-empty>
        </div>
      </div>
      <!--Start code-->
      <div class="row" v-if="news">
        <div class="col-12 pb-5">
          <!--SECTION START-->
          <section class="row">
            <!--Start slider news-->
            <div class="col-12 col-md-6 pb-0 pb-md-3 pt-2 pr-md-1" v-if="news[0]">
              <div id="featured" class="carousel slide carousel" data-ride="carousel">
                <!--carousel inner-->
                <div class="carousel-inner">
                  <!--Item slider-->
                  <div class="carousel-item active">
                    <div class="card border-0 rounded-0 text-light overflow zoom">
                      <div class="position-relative">
                        <!--thumbnail img-->
                        <div class="ratio_left-cover-1 big-image">
                          <a
                            @click="
                              $router.push({ name: 'singleNews', params: { id: news[0]._id } })
                            "
                          >
                            <img
                              :src="apiUrl + news[0].coverImg"
                              :alt="news[0].title[getTrad(news[0])]"
                            />
                          </a>
                        </div>
                        <div class="position-absolute p-2 b-0 w-100 bg-shadow">
                          <!--title-->
                          <a
                            @click="
                              $router.push({ name: 'singleNews', params: { id: news[0]._id } })
                            "
                          >
                            <h2 class="h3 post-title text-white my-1">
                              {{ news[0].title[getTrad(news[0])] }}
                            </h2>
                          </a>
                          <!-- meta title -->
                          <div class="news-meta">
                            <span class="news-date">{{
                              moment(news[0].createdAt)
                                .locale('fr', localization)
                                .format('DD/MM/YYYY HH:mm')
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end item slider-->
                </div>
                <!--end carousel inner-->
              </div>
            </div>
            <!--End slider news-->

            <!--Start box news-->
            <div class="col-12 col-md-6 pl-md-2 mt-1 mb-3 mb-lg-4" v-if="news[1]">
              <div class="row">
                <!--news box-->
                <div class="col-6 pr-1">
                  <div class="card mb-3 border-0 rounded-0 text-white overflow zoom">
                    <div class="position-relative">
                      <!--thumbnail img-->
                      <div class="ratio_right-cover-2 small-image">
                        <a
                          @click="$router.push({ name: 'singleNews', params: { id: news[1]._id } })"
                        >
                          <img
                            class=""
                            :src="apiUrl + news[1].coverImg"
                            :alt="news[1].title[getTrad(news[1])]"
                          />
                        </a>
                      </div>
                      <div class="position-absolute p-2 b-0 w-100 bg-shadow">
                        <!-- category -->
                        <a
                          class="p-1 badge badge-primary rounded-0"
                          @click="$router.push({ name: 'singleNews', params: { id: news[1]._id } })"
                          >{{ news[1].category.title[getTrad(news[1])] }}</a
                        >

                        <!--title-->
                        <a
                          @click="$router.push({ name: 'singleNews', params: { id: news[1]._id } })"
                        >
                          <h2 class="h5 text-white my-1 titledot">
                            {{ news[1].title[getTrad(news[1])] }}
                          </h2>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <!--news box-->
                <div class="col-6 pl-1" v-if="news[2]">
                  <div class="card mb-3 border-0 rounded-0 text-white overflow zoom">
                    <div class="position-relative">
                      <!--thumbnail img-->
                      <div class="ratio_right-cover-2 small-image">
                        <a
                          @click="$router.push({ name: 'singleNews', params: { id: news[2]._id } })"
                        >
                          <img
                            class=""
                            :src="apiUrl + news[2].coverImg"
                            :alt="news[2].title[getTrad(news[2])]"
                          />
                        </a>
                      </div>
                      <div class="position-absolute p-2 b-0 w-100 bg-shadow">
                        <!-- category -->
                        <a
                          class="p-1 badge badge-primary rounded-0"
                          @click="$router.push({ name: 'singleNews', params: { id: news[2]._id } })"
                          >{{ news[2].category.title[getTrad(news[2])] }}</a
                        >
                        <!--title-->
                        <a
                          @click="$router.push({ name: 'singleNews', params: { id: news[2]._id } })"
                        >
                          <h2 class="h5 text-white my-1 titledot">
                            {{ news[2].title[getTrad(news[2])] }}
                          </h2>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <!--news box-->
                <div class="col-6 pr-1" v-if="news[3]">
                  <div class="card border-0 rounded-0 text-white overflow zoom">
                    <div class="position-relative">
                      <!--thumbnail img-->
                      <div class="ratio_right-cover-2 small-image">
                        <a
                          @click="$router.push({ name: 'singleNews', params: { id: news[3]._id } })"
                        >
                          <img
                            class=""
                            :src="apiUrl + news[3].coverImg"
                            :alt="news[3].title[getTrad(news[3])]"
                          />
                        </a>
                      </div>
                      <div class="position-absolute p-2 b-0 w-100 bg-shadow">
                        <!-- category -->
                        <a
                          class="p-1 badge badge-primary rounded-0"
                          @click="$router.push({ name: 'singleNews', params: { id: news[3]._id } })"
                          >{{ news[3].category.title[getTrad(news[3])] }}</a
                        >
                        <!--title-->
                        <a
                          @click="$router.push({ name: 'singleNews', params: { id: news[3]._id } })"
                        >
                          <h2 class="h5 text-white my-1 titledot">
                            {{ news[3].title[getTrad(news[3])] }}
                          </h2>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <!--news box-->
                <div class="col-6 pl-1" v-if="news[4]">
                  <div class="card border-0 rounded-0 text-white overflow zoom">
                    <div class="position-relative">
                      <!--thumbnail img-->
                      <div class="ratio_right-cover-2 small-image">
                        <a
                          @click="$router.push({ name: 'singleNews', params: { id: news[4]._id } })"
                        >
                          <img
                            class=""
                            :src="apiUrl + news[4].coverImg"
                            :alt="news[4].title[getTrad(news[4])]"
                          />
                        </a>
                      </div>
                      <div class="position-absolute p-2 b-0 w-100 bg-shadow">
                        <!-- category -->
                        <a
                          class="p-1 badge badge-primary rounded-0"
                          @click="$router.push({ name: 'singleNews', params: { id: news[4]._id } })"
                          >{{ news[4].category.title[getTrad(news[4])] }}</a
                        >
                        <!--title-->
                        <a
                          @click="$router.push({ name: 'singleNews', params: { id: news[4]._id } })"
                        >
                          <h2 class="h5 text-white my-1 titledot">
                            {{ news[4].title[getTrad(news[4])] }}
                          </h2>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end news box-->
              </div>
            </div>
          </section>
          <!--END SECTION-->
        </div>
      </div>
      <div v-else class="m-5">
        <div class="d-flex justify-content-center">
          <div class="spinner-grow text-primary" role="status"></div>
        </div>
      </div>
      <!--end code-->
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-center">
            <router-link to="/news">
              <button class="btn btn-primary btn-squared">{{ $t('general.seeAll') }}</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiAxios from '@/services/axios'
import moment from 'moment'
import localization from 'moment/locale/fr'
import { Empty } from 'ant-design-vue'
export default {
  name: 'NewsSection',
  data: () => ({
    Empty,
    data: [],
    apiUrl: process.env.VUE_APP_API_URL,
    localization,
  }),
  computed: {
    currentLocale() {
      return this.$i18n.locale.slice(0, 2)
    },
    news() {
      return this.data.filter((data) => data.title[this.currentLocale])
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    moment,
    getTrad(data) {
      if (data.title.en && this.$i18n.locale == 'en-US') return 'en'
      if (data.title.ar && this.$i18n.locale == 'ar-EG') return 'ar'
      return 'fr'
    },
    getData() {
      apiAxios
        .post('/actualites/filter', {
          query: { status: 'active' },
        })
        .then((res) => {
          res.data.sort(function (x, y) {
            return x.top === y.top ? 0 : x.top ? -1 : 1
          })
          this.data = res.data
        })
        .finally(() => {})
    },
  },
}
</script>
<style scoped>
/* Credits to https://bootsnipp.com/snippets/1d3X9 */

.b-0 {
  bottom: 0;
}
.bg-shadow {
  background: rgba(76, 76, 76, 0);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(179, 171, 171, 0)),
    color-stop(49%, rgba(48, 48, 48, 0.37)),
    color-stop(100%, rgba(19, 19, 19, 0.8))
  );
  background: linear-gradient(
    to bottom,
    rgba(179, 171, 171, 0) 0%,
    rgba(48, 48, 48, 0.71) 49%,
    rgba(19, 19, 19, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=0 );
}

.top-indicator {
  right: 0;
  top: 1rem;
  bottom: inherit;
  left: inherit;
  margin-right: 1rem;
}
.overflow {
  position: relative;
  overflow: hidden;
}
.zoom img {
  transition: all 0.2s linear;
}
.zoom:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.big-image {
  height: 365px;
  background: #eee;
  text-align: center;
}
.big-image img {
  height: 100%;
  text-align: center;
  -o-object-fit: cover;
  object-fit: cover;
}

.small-image {
  height: 178px;
  background: #eee;
  text-align: center;
}

.small-image img {
  text-align: center;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.titledot {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
<style lang="scss" scoped>
@import '@/mixins.scss';
.title {
  border-bottom: 2px solid $primary;
  width: 7em;
}

a.badge-primary:focus,
a.badge-primary:hover {
  background-color: $secondary;
}
</style>