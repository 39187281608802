<template>
 <div class="card timeline">
     <div class="card-title titre">
         {{getMomentFormat(date,"DD-MM-YYYY")}}
     </div>
     <div class="card-body">
    <div class="container py-2" v-if="data.length > 0">
  <!-- timeline item 2 -->
  <div class="row" v-for="event in data" :key="event._id">
    <div class="col-auto text-center flex-column d-none d-sm-flex">
      <div class="row h-50">
        <div class="col border-right">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
      <h5 class="m-2">
        <span class="badge badge-pill bg-primary border">&nbsp;</span>
      </h5>
      <div class="row h-50">
        <div class="col border-right">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
    </div>
    <div class="col py-4 px-2">
      <div class="card shadow">
        <div class="card-body px-2 py-2">
          <div class="float-right">{{getMomentFormat(event.date,"HH:mm")}}</div>
          <h4 class="card-title">{{event.title[getTrad(event)]}}</h4>
          <p class="card-text">{{event.content[getTrad(event)]}}</p>
        </div>
      </div>
    </div>
  </div>
  <!--/row-->
</div>
<div v-else>
  <a-empty
    :image="Empty.PRESENTED_IMAGE_SIMPLE"
    :image-style="{
      height: '60px',
    }"
  >
    <template #description>
      <span>
        Pas d'évènement
      </span>
    </template>
  </a-empty>
</div>
<!--container-->
     </div>
 </div>
</template>
<script>
import moment from 'moment'
import localization from 'moment/locale/fr'
import { Empty } from 'ant-design-vue';


export default {
  props: {
      data: {type: Object, default: null},
      date: {type: Date, default: null},
    },
  data: () => ({
    Empty,
  }),
  methods: {
    moment,
    getTrad(data){
        if(data.title.en && this.$i18n.locale == 'en-US') return 'ar'
        if(data.title.ar && this.$i18n.locale == 'ar-EG') return 'ar'
          return 'fr'
    },
    getMomentFormat(value,format){
      if(value){
        return moment(value).locale("fr", localization).format(format)
      }
      return moment().locale("fr", localization).format(format)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.container {
   height: 300px;
   overflow-x: auto;
}

.titre {
    color: $secondary;
    font-size: 2rem;
    padding-left: 3rem;
    margin-bottom: 0rem;
    color: $white;
    background-color: $primary;
}

.timeline {
    border: 1px solid $primary !important;
}

.card-body {
    padding: 0rem 0rem;
}
</style>