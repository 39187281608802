<template>
    <div class="background-fade">
        <div class="container mt-5">
            <div class="row">
                <div class="col-12 pt-3">
                    <h2 class="title pl-1">{{$t('menu.agenda')}}</h2>
                </div>
            </div>

	<div class="row">
        <div class="col-md-4 col-12">
            <div :style="{ width: '300px', border: '1px solid #d9d9d9', borderRadius: '4px' }">
                <a-calendar v-model:value="value" :fullscreen="false" @panelChange="onPanelChange" @select="selectDay" />
            </div>
        </div>
        <div class="col-md-8 mt-2 mt-sm-0 col-12">
            <eventsCard :data="dayEvents" :date="value"/>
        </div>
    </div>
    <div class="d-flex justify-content-center mx-auto">
       <router-link to="/agenda">
            <button class="btn btn-primary btn-squared mt-5 mb-5">{{$t('general.seeAll')}}</button>
        </router-link>
    </div>
</div>
    </div>
</template>
<script>
import EventsCard from '@/components/EventsCard'
import { defineComponent, ref } from 'vue';
import moment from 'moment'
import localization from 'moment/locale/fr'
import apiAxios from '@/services/axios'

export default defineComponent({
  components:{ EventsCard },
  setup() {
    const value = ref();

    const onPanelChange = (value, mode) => {
      console.log(value, mode);
    };

    return {
      value,
      onPanelChange,
    };
  },
  data: () => ({
    dayEvents: [],
    events: [],
  }),
  mounted() {
      this.getEvents()
  },
  methods: {
    moment,
    selectDay: function(value){
      this.dayEvents = this.events.filter(el => {
          return moment(el.date).locale("fr", localization).format("MM-DD-YYYY") == moment(value).locale("fr", localization).format("MM-DD-YYYY")
      })
    },
    getListData: function(value) {
         let listData = this.events.filter(el => {
          return moment(el.date).locale("fr", localization).format("MM-DD-YYYY") == moment(value).locale("fr", localization).format("MM-DD-YYYY")
      })
      return listData;
    },
    getEvents: function() {
          apiAxios
        .post('/agandas/filter',{query:{status: "active"}})
        .then(res => {
          this.events = res.data
          this.selectDay(this.moment())
        })
        .finally(() => {
          //this.tableLoading=false
        })
    },
  },
});
</script>
<style lang="scss" scoped>
   @import '@/mixins.scss';
    .title {
        border-bottom: 2px solid $primary;
        width: 7em;
    }

    .vc-container {
        border-color: $primary;
        border-radius: 0rem;
    }
</style>