<template>
  <div class="background-fade">
    <div class="container mt-5">
      <div class="row">
        <div class="col-12 pt-3">
          <h2 class="title pl-1">{{ $t('menu.posts') }}</h2>
        </div>
      </div>
      <div class="row" v-if="posts && posts.length == 0">
        <div class="col-12 pb-2">
          <a-empty
            :image="Empty.PRESENTED_IMAGE_SIMPLE"
            :image-style="{
              height: '60px',
            }"
          >
            <template #description>
              <span>
                {{ $t('general.noData') }}
              </span>
            </template>
          </a-empty>
        </div>
      </div>
      <div class="row" v-if="posts">
        <div class="col-sm-6 col-md-6 col-lg-3" v-if="posts[0]">
          <book-card :data="posts[0]" />
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3" v-if="posts[1]">
          <book-card :data="posts[1]" />
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3" v-if="posts[2]">
          <book-card :data="posts[2]" />
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3" v-if="posts[3]">
          <book-card :data="posts[3]" />
        </div>
      </div>
      <div v-else class="m-5">
        <div class="d-flex justify-content-center">
          <div class="spinner-grow text-primary" role="status"></div>
        </div>
      </div>
      <div class="d-flex justify-content-center mx-auto ">
        <router-link to="/posts">
          <button class="btn btn-primary btn-squared mt-5 mb-5">{{ $t('general.seeAll') }}</button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import BookCard from '@/components/BookCard'
import apiAxios from '@/services/axios'
import { Empty } from 'ant-design-vue'

export default {
  components: { BookCard },
  data: () => ({
    Empty,
    data: [],
  }),
  computed: {
    currentLocale() {
      return this.$i18n.locale.slice(0, 2)
    },
    posts() {
      let filtered = this.data.filter(data => data.title[this.currentLocale])
      console.log(filtered)
      return filtered
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      apiAxios
        .post('/publications/filter', {
          query: { status: 'active', coverImg: { $exists: true, $ne: null } },
        })
        .then(res => {
          this.data = res.data
        })
        .finally(() => {})
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.title {
  border-bottom: 2px solid $primary;
  width: 7em;
}
</style>
