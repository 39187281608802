<template>
  <div :class="{ arabicFont: language === 'ar-EG' }">
    <landing-page-header></landing-page-header>

    <news-section id="news"></news-section>

    <pubs-section id="posts"></pubs-section>
<VideoSection></VideoSection>
    <agenda-section id="agenda"></agenda-section>

    <debat-section id="debate"></debat-section>
  </div>
</template>

<script>
import LandingPageHeader from '@/components/LandingPageHeader'
import NewsSection from '@/components/NewsSection'
import PubsSection from '@/components/PubsSection'
import AgendaSection from '@/components/AgendaSection'
import DebatSection from '@/components/DebatSection'
import VideoSection from '@/components/VideoSection'
import { useStore } from 'vuex'
import { computed } from 'vue'
//Vuejs slides
import ApiClient from '@/services/axios'
import { ref } from 'vue'

export default {
  name: 'VbAcceuil',
  components: {
    LandingPageHeader,
    NewsSection,
    PubsSection,
    AgendaSection,
    DebatSection,
    VideoSection,
  },
  setup() {
        const store = useStore()
    const language = computed(() => store.getters.settings.locale)

    const baseUrl = ref(process.env.VUE_APP_API_URL)
    const slides = ref([])
    ApiClient.post('/slider/filter', {
      query: {status:'active'},
    })
      .then((res) => {
        slides.value = res.data.reverse() 
      })
      .catch((err) => console.log(err))
    return {
      language,
      slides,
      baseUrl,
    }
  },
}
</script>
<style scoped>
.arabicFont {
  font-family: 'Changa', sans-serif;
}
</style>
